import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import NeoValidadorFooter from "../projects/neovalidador/commons/Footer/NeoValidadorFooter";
import NeoValidadorNavBar from "../projects/neovalidador/commons/Navbar/NeoValidadorNavBar";

const NeoValidador = () => {
  const LandingPage = lazy(() => import("../projects/neovalidador"));
  return (
    <Suspense fallback={null}>
      <LandingPage />
    </Suspense>
  );
};

export default NeoValidador;
